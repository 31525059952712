import React from "react";
import MultiplePizzas from "../assets/img1.jpg";
import "./AboutStyles.css";
function About() {
  return (
    <div className="about">
      <div
        className="aboutTop"
        style={{ backgroundImage: `url(${MultiplePizzas})` }}
      ></div>
      <div className="aboutBottom">
        <h1> About Lakeview Cottage</h1>
        <div className="pictures">
        <img src="https://i.imgur.com/xNsDepc.jpg"></img>
        <img src="https://i.imgur.com/X1uF3Jp.jpg"></img>
        <img src="https://i.imgur.com/eiqdmSf.jpg"></img>
        </div>
        <p>
          Bring your family and enjoy the beautiful views of the Ontario Lake at this
          cozy and relaxing cottage. The cottage has plenty of room for your family and guests
          with 4 bedrooms and 2 full washrooms. It is very clean and well-maintained and also 
          offers plenty of amenities and entertainment such as:
        </p>
        <ul>Cable TV and Netflix</ul>
        <ul>Karaoke and Billiards/Ping Pong Table</ul>
        <ul>Complete kitchen and dining sets</ul>
        <ul>Air conditioner and heating</ul>
        <ul>Available wifi and security cameras</ul>
        <ul>Patio, balcony, fire pits, swings and trampoline</ul>
      </div>
    </div>
  );
}

export default About;