import React from 'react'
import './HeroImageStyles.css'
import {Link} from 'react-router-dom'

function HeroImage() {
  return (
  <div className='hero-img'> 
    <div className='header-container'>
      <h1>Holidays in Lakeview Cottage</h1>
      <p>A place for the family</p>
      <Link to='/about' className='btn'>About</Link>
      <Link to='/gallery' className='btn'>Gallery</Link>
      <Link to='/contact' className='btn'>Contact</Link>
    </div>
  </div>
  )
}

export default HeroImage;