import React, {useState} from 'react'
import './GalleryCompStyles.css'

import Img1 from '../assets/img1.jpg'
import Img2 from '../assets/img2.jpg'
import Img3 from '../assets/img3.jpg'
import Img4 from '../assets/img4.jpg'
import Img5 from '../assets/img5.jpg'
import Img6 from '../assets/img6.jpg'
import Img7 from '../assets/img7.jpg'
import Img8 from '../assets/img8.jpg'
import Img9 from '../assets/img9.jpg'
import Img10 from '../assets/img10.jpg'
import Img11 from '../assets/img11.jpg'
import Img12 from '../assets/img12.jpg'
import Img13 from '../assets/img13.jpg'
import Img14 from '../assets/img14.jpg'
import Img15 from '../assets/img15.jpg'
import Img16 from '../assets/img16.jpg'
import Img17 from '../assets/img17.jpg'
import Img18 from '../assets/img18.jpg'
import Img19 from '../assets/img19.jpg'
import Img20 from '../assets/img20.jpg'
import Img21 from '../assets/img21.jpg'
import Img22 from '../assets/img22.jpg'
import Img23 from '../assets/img23.jpg'
import Img24 from '../assets/img24.jpg'
import Img25 from '../assets/img25.jpg'
import Img26 from '../assets/img26.jpg'
import Img27 from '../assets/img27.jpg'
import Img28 from '../assets/img28.jpg'
import Img29 from '../assets/img29.jpg'
import Img30 from '../assets/img30.jpg'
import Img31 from '../assets/img31.jpg'
import Img32 from '../assets/img32.jpg'
import Img33 from '../assets/img33.jpg'
import  CloseIcon from '@material-ui/icons/Close'

const GalleryComp = () => {
    let data =[
        {
            id: 1,
            imgSrc: Img1,
        },
        {
            id: 2,
            imgSrc: Img2,
        },
        {
            id: 3,
            imgSrc: Img3,
        },
        {
            id: 4,
            imgSrc: Img4,
        },
        {
            id: 5,
            imgSrc: Img5,
        },
        {
            id: 6,
            imgSrc: Img6,
        },
        {
            id: 7,
            imgSrc: Img7,
        },
        {
            id: 8,
            imgSrc: Img8,
        },
        {
            id: 9,
            imgSrc: Img9,
        },
        {
            id: 10,
            imgSrc: Img10,
        },
        {
            id: 11,
            imgSrc: Img11,
        },
        {
            id: 12,
            imgSrc: Img12,
        }, 
        {
            id: 13,
            imgSrc: Img13,
        },
        {
            id: 14,
            imgSrc: Img14,
        },
        {
            id: 15,
            imgSrc: Img15,
        },
        {
            id: 16,
            imgSrc: Img16,
        },
        {
            id: 17,
            imgSrc: Img17,
        },
        {
            id: 18,
            imgSrc: Img18,
        },
        {
            id: 19,
            imgSrc: Img19,
        },
        {
            id: 20,
            imgSrc: Img20,
        },
        {
            id: 21,
            imgSrc: Img21,
        },
        {
            id: 22,
            imgSrc: Img22,
        },
        {
            id: 23,
            imgSrc: Img23,
        },
        {
            id: 24,
            imgSrc: Img24,
        },
        {
            id: 25,
            imgSrc: Img25,
        },
        {
            id: 26,
            imgSrc: Img26,
        },
        {
            id: 27,
            imgSrc: Img27,
        },
        {
            id: 28,
            imgSrc: Img28,
        },
        {
            id: 29,
            imgSrc: Img29,
        },
        {
            id: 30,
            imgSrc: Img30,
        },
        {
            id: 31,
            imgSrc: Img31,
        },
        {
            id: 32,
            imgSrc: Img32,
        },
        {
            id: 33,
            imgSrc: Img33,
        }
    ]
    const [model, setModel] = useState(false);
    const [tempImgSrc, setTempImgSrc] = useState('');

    const getImg = (imgSrc) => {
        setTempImgSrc(imgSrc);
        setModel(true);
    }

    return (
        <>
        <div className={model?"model open" : "model"}>
            <img src={tempImgSrc} />
            <CloseIcon onClick={()=>setModel(false)}/>
        </div>

        <div className='gallery'>
            {data.map((item, index)=> {
                return(
                    <div className='pics' key={index} onClick={() => getImg(item.imgSrc)}>
                        <img src={item.imgSrc} style={{width: '100%'}} />
                    </div>
                )
            })}
        </div>
        </>
    )
}

export default GalleryComp