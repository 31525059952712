import React from "react";
import "./FooterStyles.css";
import {FaAirbnb, FaMailBulk, FaPhone} from 'react-icons/fa'


function Footer() {
  return (
    <div className="footer">
      <div className="socialMedia">
        <a href="/contact">
        <FaMailBulk size={40} style={{color: '#000000', marginRight: '1rem'}}/>
        </a>
      </div>
      <p> &copy; 2022 lakeview-cottage.com</p>
    </div>
  );
}

export default Footer;

