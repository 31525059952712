import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import Contact from '../components/Contact'

const Gallery = () => {
  return (
    <div>
        <Navbar />
        <Contact />
        <Footer />
    </div>
  )
}

export default Gallery