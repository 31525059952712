import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import About from '../components/About'

const Gallery = () => {
  return (
    <div>
        <Navbar />
        <About />
        <Footer />
    </div>
  )
}

export default Gallery