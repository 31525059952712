import React from "react";
import HouseLeft from "../assets/img19.jpg";
import "./ContactStyles.css";
import emailjs from 'emailjs-com'

function Contact() {
    function sendEmail(e){
        e.preventDefault();
        emailjs.sendForm(
        'service_v70ikzr', 
        'template_c5ekz4m', 
        e.target, 
        'O1E94IiNtLJoo6OaH').then(res=>{
            console.log(res);
        }).catch(err=>console.log(err));
    }

  return (
    <div className="contact">
      <div
        className="leftSide"
        style={{ backgroundImage: `url(${HouseLeft})` }}
      ></div>
      <div className="rightSide">
        <h1> Contact Us</h1>
        <p>Phone #</p>

        <form id="contact-form" method="POST" onSubmit={sendEmail}>
          <label htmlFor="name">Full Name</label>
          <input name="name" placeholder="Enter full name..." type="text" />
          <label htmlFor="email">Email</label>
          <input name="email" placeholder="Enter email..." type="email" />
          <label htmlFor="message">Message</label>
          <textarea
            rows="6"
            placeholder="Enter message..."
            name="message"
            required
          ></textarea>
          <button type="submit"> Send Message</button>
        </form>
      </div>
    </div>
  );
}

export default Contact;