import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import GalleryComp from '../components/GalleryComp'

const Gallery = () => {
  return (
    <div>
        <Navbar />
        <GalleryComp />
        <Footer />
    </div>
  )
}

export default Gallery